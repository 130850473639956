
@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito_Sans'), url('./fonts/Nunito_Sans/NunitoSans-Regular.ttf')
}

@font-face {
  font-family: 'Playfair Display';
  src: local('Playfair_Display'), url('./fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf')
}

@font-face {
  font-family: 'Dancing Script';
  src: local('Dancing_Script'), url('./fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf')
}

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "5x3": calc(3 / 5 * 100%),
  "7x4": calc(4 / 7 * 100%)
);

$theme-colors: (
  'primary': #252739,
  'secondary': #1c242c,
  'ternary': #23a455,
  'ghost': #fff,
  'danger': #ff0000,
  'geniebelt': #f69a13,
  'genie': #14eaff
);

$theme-one-btn: #23a455;
$theme-two-btn: #B40101;
$theme-three-btn: #BEAF87;
$theme-four-btn: #012169;
$theme-five-btn: #dc1c2e;
$theme-six-btn: #C82021;

$theme-one-main-background: #252739;
$theme-two-main-background: #343342;
$theme-three-main-background: #252526;
$theme-four-main-background: #545759;
$theme-five-main-background: #003da5;
$theme-six-main-background: #333;

$theme-one-card-background: #2e2e3d;
$theme-two-card-background: scale-color($theme-two-main-background, $lightness: -10%);
$theme-three-card-background: scale-color($theme-three-main-background, $lightness: -10%);
$theme-four-card-background: scale-color($theme-four-main-background, $lightness: -20%);
$theme-five-card-background: scale-color($theme-five-main-background, $lightness: -10%);
$theme-six-card-background: scale-color($theme-six-main-background, $lightness: -10%);

$theme-one-agent-background: #212529;
$theme-two-agent-background: scale-color($theme-two-main-background, $lightness: -25%);
$theme-three-agent-background: scale-color($theme-three-main-background, $lightness: -25%);
$theme-four-agent-background: scale-color($theme-four-main-background, $lightness: -50%);
$theme-five-agent-background: scale-color($theme-five-main-background, $lightness: -25%);
$theme-six-agent-background: scale-color($theme-six-main-background, $lightness: -25%);

$theme-one-main-font: #fff;
$theme-two-main-font: #fff;
$theme-three-main-font: #fff;
$theme-four-main-font: #fff;
$theme-five-main-font: #fff;
$theme-six-main-font: #fff;

.ui.segments {
  box-shadow: none !important;
  border: none !important;
}

.ui.segment {
  box-shadow: none !important;
}

.main-font-style {
  font-family: 'Nunito Sans', sans-serif !important;
}

.secondary-font-style {
  font-family: 'Playfair Display', sans-serif !important;
}

.fancy-font-style {
  font-family: 'Dancing Script' !important;
}

.main-view {
  max-width: 550px;
}

.theme-one {
  background-color: $theme-one-main-background !important;
  .variable-checks {
    color: $theme-one-btn !important;
  }
  .variable-main-font-color {
    color: $theme-one-main-font;
  }
  .desc-card-bg-color {
    background-color: #2e2e3d;
  }
  .profile-agent-sect-color {
    background-color: #1c242c;
  }
  .variable-buttons {
    background-color: $theme-one-btn !important;
  }
}

.theme-two {
  background-color: $theme-two-main-background !important;
  .variable-buttons {
    background-color: $theme-two-btn !important;
  }
  .variable-checks {
    color: $theme-two-btn !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-two-main-font !important;
  }
  .desc-card-bg-color {
    background-color: $theme-two-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-two-agent-background;
  }
}

.theme-three  {
  background-color: $theme-three-main-background !important;
  .variable-buttons {
    background-color: $theme-three-btn !important;
  }
  .variable-checks {
    color: $theme-three-btn !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-three-main-font;
  }
  .desc-card-bg-color {
    background-color: $theme-three-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-three-agent-background;
  }
}

.theme-four {
  background-color: $theme-four-main-background !important;
  .variable-buttons {
    background-color: $theme-four-btn !important;
  }
  .variable-checks {
    color: $theme-four-btn !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-four-main-font;
  }
  .desc-card-bg-color {
    background-color: $theme-four-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-four-agent-background;
  }
}

.theme-five  {
  background-color: $theme-five-main-background !important;
  .variable-buttons {
    background-color: $theme-five-btn !important;
  }
  .variable-checks {
    color: $theme-five-btn !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-five-main-font;
  }
  .desc-card-bg-color {
    background-color: $theme-five-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-five-agent-background;
  }
}

.theme-six {
  background-color: $theme-six-main-background !important;
  .variable-buttons {
    background-color: $theme-six-btn !important;
  }
  .variable-checks {
    color: $theme-six-btn !important;
  }
  .top-buttons {
    background-color: $theme-six-main-background;
  }
  .top-buttons:hover {
    background-color: $theme-six-main-background;
    color: $theme-two-main-font !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-six-main-font;
  }
  .desc-card-bg-color {
    background-color: $theme-six-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-six-agent-background;
  }
}

.modal {
  left: auto !important;
}

.no-border {
  border: none !important;
}
 
.date-rail {
  margin-top: 50% !important;
}

.profile-view {
  height: auto;
}

.resp-font-size {
  font-size: .8rem;
}

.show-button {
  font-size: .7rem !important;
}

.avatar-img {
  width: 2.5em !important;
  height: 2.5em !important;
}

.profile-img-sect {
  height: 35%;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-x: center;
  background-size: cover;
}

.arrow-icon-div {
  margin-top: 0 !important;
}

.arrow-icon {
  font-size: .5em !important;
}

.arrow-font {
  font-size: .6rem !important;
}

.arrow-col-pad {
  padding-top: .3rem !important;
}

.carousel-control-prev, .carousel-control-next {
  width: 5% !important;
  height: 50% !important;
  bottom: 4.5rem !important;
  top: auto !important;
}

.carousel-indicators {
  position: relative !important;
}

.carousel-control-prev-icon {
  display: none !important;
}

.carousel-control-next-icon {
  display: none !important;
}

.carousel-inner {
  height: 100% !important;
}

.carousel-button:hover {
  background-color: transparent !important;
  color: black !important;
}

.modal-backdrop.show {
  opacity: .85 !important;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@media (min-width: 540px) {
  .resp-font-size {
    font-size: 1.14285714rem;
  }
  .show-button {
    font-size: 1rem !important;
  }
  .avatar-img {
    width: 4em !important;
    height: 4em !important;
  }
  .arrow-icon {
    font-size: 1.5em !important;
  }
  .arrow-font {
    font-size: .8rem !important;
  }
  .arrow-col-pad {
    padding-top: 1rem !important;
  }
  .arrow-icon-div {
    margin-top: .3rem !important;
  }
}

@media (min-width: 768px) {
  .carousel-control-prev-icon {
    display: inline-block !important;
  }
  .carousel-control-next-icon {
    display: inline-block !important;
  }
}

@media (max-width: 400px) {
  .date-rail {
    font-size: 1.2rem !important;
  }
}

@media (min-height: 720px) {
  .profile-wrapper {
    height: auto;
  }
  .profile-view {
    height: auto;
  }
}


@import '~bootstrap/scss/bootstrap';